import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdminGuard } from './admin/admin.guard';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AuthInterceptor } from './Interceptor/interceptor';
import { NotificationInfoModalComponent } from './modal-components/notification-info-modal/notification-info-modal.component';
import { ConfirmModalComponent } from './modal-components/confirm-modal/confirm-modal.component';
import { FacilityInfoModalComponent } from './modal-components/facility-info-modal/facility-info-modal.component';
import { RiverSystemInfoModalComponent } from './modal-components/river-system-info-modal/river-system-info-modal.component';
import { RegionInfoModalComponent } from './modal-components/region-info-modal/region-info-modal.component';
import { UserPermissionInfoModalComponent } from './modal-components/user-permission-info-modal/user-permission-info-modal.component';
import { FacilityInfoLinkModalComponent } from './modal-components/facility-info-link-modal/facility-info-link-modal.component';
import { HomepageBannerModalComponent } from './modal-components/homepage-banner-modal/homepage-banner-modal.component';
import { HomepageSliderModalComponent } from './modal-components/homepage-slider-modal/homepage-slider-modal.component';
import { AttachmentModalComponent } from './modal-components/attachment-modal/attachment-modal.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FacilityMetricModalComponent } from './modal-components/facility-metric-modal/facility-metric-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    AppComponent,
    NotificationInfoModalComponent,
    ConfirmModalComponent,
    FacilityInfoModalComponent,
    RiverSystemInfoModalComponent,
    RegionInfoModalComponent,
    UserPermissionInfoModalComponent,
    FacilityInfoLinkModalComponent,
    HomepageBannerModalComponent,
    HomepageSliderModalComponent,
    AttachmentModalComponent,
    FacilityMetricModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
      closeButton: true,
    }),
    NgxSpinnerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      placeholder: ''
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, AdminGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
